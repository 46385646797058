import React from "react";
import styled from "styled-components";

const PageCopy = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  padding: 4rem 2rem;
  margin: 2rem;
  /* border-radius: 1rem; */
  /* box-shadow: var(--default-box-shadow); */
  p {
    /* max-width: 10rem; */
  }
`;

export default function ({ pageCopy }) {
  return (
    <PageCopy>
      {pageCopy.map((paragraph) => (
        <p>{paragraph}</p>
      ))}
    </PageCopy>
  );
}
