import React from "react";
import GlobalStyles from "../styles/GlobalStyles";

export default function ActiveBetweenRange({ dates }) {
  console.log({ dates });
  return (
    <p>
      {dates[0] ? "Creating art since " + dates[0] + "." : ""}
      {dates[1] ? " Ceased creation in " + dates[1] + "." : ""}
    </p>
  );
}
