import * as React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageCopy from "../components/PageCopy";
import ActiveBetweenRange from "../components/ActiveBetweenRange";

const ArtistInfo = styled.li`
  list-style: none;
  display: grid;
  grid-template-columns: 9rem 1fr;
  grid-template-areas:
    "img title"
    "img artist"
    "img material";
  padding: 1rem;
  &:hover {
    background-color: var(--brand-blue);
    cursor: pointer;
    img {
      transform: scale(1.2);
      transition: transform 1.5s ease-in-out;
    }
  }
  img {
    transform: scale(1);
    transition: transform 1.5s ease-in-out;
    border-radius: 0.5rem;
  }
  div {
    width: 70px;
    grid-area: img;
  }
  h2 {
    grid-area: title;
    margin: 0;
  }
  p:nth-child(1) {
    grid-area: artist;
  }
  p:nth-child(2) {
    grid-area: material;
  }
  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export default function Artists({ data }) {
  const artists = data.artists.nodes;
  // const count = data.artworks.totalCount;
  const pageCopy = [
    // data.intro.artistIntro.split("\n"),
    `Copy to come`,
    // `There are ${count} pieces in the collection.`,
  ];

  console.log(pageCopy);

  return (
    <div className="pageContent">
      <h1>Artists of MOMH</h1>
      <PageCopy pageCopy={pageCopy} />
      <ul>
        {artists.map((artist, i) => {
          return (
            <>
              <Link to={artist.slug.current}>
                <ArtistInfo>
                  <GatsbyImage
                    className="image"
                    image={getImage(artist.image?.asset)}
                    alt={`artist.name`}
                  />
                  <h2>{artist.name}</h2>
                  <ActiveBetweenRange
                    dates={[artist.yearActiveStart, artist.yearActiveEnd]}
                  />
                </ArtistInfo>
              </Link>
              <hr />
            </>
          );
        })}
      </ul>
    </div>
  );
}

export const query = graphql`
  query {
    # intro: sanityWebsiteCopy {
    #   artistIntro
    # }
    artists: allSanityArtist {
      nodes {
        id
        dob
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 100)
          }
        }
        name
        slug {
          current
        }
        yearActiveEnd
        yearActiveStart
        bio {
          children {
            text
          }
        }
      }
    }
  }
`;
